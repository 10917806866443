<template>
    <seccion-datos :titulo="tipodesistema" @cambios="guardar($data, 'tipoSistema')">
        <div class="form-group">
            <label>{{ $t('general.conexion') }}</label>
            <select class="custom-select form-control-sm" v-model="sistemaSelec">
                <option value="">{{ $t('general.seleccionesistemaconexion') }}</option>
                <option v-for="sistema in listadosistemas" :value="sistema" :key="sistema.id" >{{ sistema.nombre }}</option>
            </select>
        </div>
        <!-- Inicio Opciones sistema -->
            <div v-if="mostrarSelec">
                <h5>{{ $t('general.opcionesdeconexion') }}</h5>
                <!-- Inicio Opciones web -->
                <div v-if="sistemaSelec.configuracion.campos">
                    <div class="row" v-for="[id, datos] in Object.entries(sistemaSelec.configuracion.campos)" :key="id">                        
                        <div class="col-md-12">
                            <div class="card card-info card-outline">   
                                <div class="card-header">
                                    <h6 class="card-title">{{id}}</h6>
                                </div>                                                           
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4" v-for="[id2, dato] in Object.entries(datos)" :key="id2">
                                            <div class="form-group">
                                                <label>{{ dato.etiqueta }}</label>
                                                <input type="text" class="form-control form-control-sm" :placeholder="dato.descripcion" v-model="dato.valor">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </seccion-datos>
</template>
<script>
import $ from 'jquery';
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: {
        datos: {
            type: Object
        },
    },
    inject: ['guardar'],
    data() {
        return{
            sistema_compannia: null,
            codigo_usuario_companyia: null,
            codigo_clave_companyia: null,
            correo_colaborador_pap: null,
            correo_pass_colaborador_pap: null,
            servidor_correo_colaborador_pap: null,
            usuario_web: null, 
            clave_web: null, 
            tipodesistema: 'Tipo de sistema',
            sistemaSelec:'',
            mostrarSelec:false,
            listadosistemas:[],
        }
    },
    computed: {
        sinSistema() {
            return this.sistema_compannia === '' 
                    ||
                   this.sistema_compannia === 'ninguno';
        },
        correos() {
            let aux = this.$store.getters['datosApp/correos'];
            return aux.datos;
        }
    },
    watch: {
        datos() {
            this.init();
            this.cargarsistemas();

        },
        sistemaSelec(value){
            value == ''?this.mostrarSelec=false:this.mostrarSelec=true;
            console.log('muestra',this.mostrarSelec);        
        },
        id() {
            this.init();
            this.cargarsistemas();

        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
            const componente = this;
            $(function() {
                const $select = $('.select2');
                $select.select2({
                    theme: 'bootstrap4'
                }).select2('val', this.sistema_compannia);
                $select.on('select2:select', function(e) {
                    var data = e.params.data;
                    componente.sistema_compannia = data.id;
                });
            });
            this.tipodesistema = this.$t('general.tipodesistema');
        },
        async cargarsistemas() {
            //GET  modulos/pwgsapi/index.php/sistemas/estado
            this.sistemaSelec = '';
            const api = new PwgsApi();
            let aux = await api.get('sistemas/'+this.datos.idcompañia);
            this.listadosistemas = aux.datos;
            var sistact = this.datos.sistema_compannia;
            var encontrado = this.listadosistemas.find(sistema => sistema.nombre == sistact);
            console.log('enconr', encontrado);
            this.sistemaSelec = encontrado;
        }
    },
    created() {
        this.init();
    },
    mounted() {
        this.cargarsistemas();
    }
}
</script>